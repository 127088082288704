// Timeline

$timeline-axis-width: 2px !default;
$timeline-axis-color: $border-color !default;
$timeline-left: 1rem !default;

$timeline-step-bg: $white !default;
$timeline-step-width: 26px !default;
$timeline-step-height: $timeline-step-width !default;
$timeline-step-radius: 50% !default;
$timeline-step-transform: translateX(-50%) !default;
$timeline-step-line-height: 1.4 !default;

$timeline-step-border-width: 2px !default;
$timeline-step-border-color: $timeline-axis-color !default;

$timeline-content-margin-left: 45px !default;
$timeline-content-padding-top: .35rem !default;
$timeline-content-top: -6px !default;
